import instance from "./request";

/********************************************公钥获取**************************************/
export const GetPublishKeyAPI = () => instance.get('/hei.api.secret/create.key');

/********************************************** 系统账号 ****************************** */
//登录的请求
export const LoginApi = (params) => instance.post("/admin/login",params);
//用户修改密码
export const UpdatePasswordApi = (params) => instance.post("/admin/updPassword",params);
//获取用户可以访问的路由api
export const GetUserRoutersApi = () => instance.get('/admin/getRouters');
//获取用户信息
export const GetUserInfoApi = () => instance.get('/admin/getUserInfo');
//创建子账号
export const CreateUserSubAPI = (params) => instance.post('/admin.sub/create', params);
//获取用户子账号信息
export const GetSubAccountDataAPI = (params) => instance.get('/admin.sub/select', {params});
//删除子账号
export const DeleteUserSubAPI = (params) => instance.get('/admin.sub/delete', {params});
//获取子账号管理的主播数据
export const GetSubManageAnchorDataAPI = (params) => instance.get('/admin.sub/get.manage.anchors', {params});
//获取子账号（代理人）的选择器
export const GetLeaderSelectDataAPI = () => instance.get('/admin.sub/select.label');


/***************************************** 一键加购 *****************************************/
//一键加购--活动列表展示
export const GetYunJgListApi = (params) => instance.get('/yunjg/actListPage',{params})

//一键加购--活动列表根据id删除
export const DeleteYunJgByIdApi = (params) => instance.get('/yunjg/delete',{params})

//一键加购--活动列表根据id获取加购信息
export const YunJgGetByIdApi = (params) => instance.get('/yunjg/getOne',{params})

//一键加购--活动列表根据id获取加购信息（新的根据id查询）
export const YunJgGetByIdNewApi = (params) => instance.get('/yunjg/getOneNew',{params})

//一键加购--活动列表根据id修改加购信息
export const UpdateYunJgByIdApi = (params) => instance.post('/yunjg/update',params)

//一键加购--活动列表根据id修改加购信息(新的修改)
export const UpdateYunJgByIdNewApi = (params) => instance.post('/yunjg/updateNew',params)

//一键加购--活动列表--新建加购
export const InsertYunJgApi = (params) => instance.post('/yunjg/insert',params)

//一键加购--活动列表--新建加购(生成链接)
export const getCreateurlApi = (params) => instance.post('/yunjg/createurl',params)

//一键加购--活动列表--新建加购（获取活动总条数）
export const GetYunJgListCountApi = () => instance.get('/yunjg/getCount')

/**
 ******************************************************主播管理--接口*********************************************
 */
//主播管理 -- 主播 -- 获取当前MCN的主播数数据
export const GetMCNAnchorNumberDataAPI = (params) => instance.get('/tb.live.anchor/stat.count',{params});

//主播管理 -- 获取淘宝登录二维码
export const GenerateQrcodeLoginTaobaoApi = () => instance.get('/tb_live/anchor/qrcode/generate')

//主播管理 -- 检查登录二维码状态
export const CheckedLoginQrcodeApi = (params) => instance.post('/tb_live/anchor/qrcode/query',params);

//主播管理 登录校验 手机验证码校验 -- 发送验证码
export const SendCodeAPI = (params) => instance.get('/tb_login/verify/phone/send_code.do',{params});

//主播管理 登录校验 手机校验码 -- 进行校验码的身份认证
export const IdentityVerifyAPI = (params) => instance.get('/tb_login/verify/confirm/identity.verify',{params});

//主播管理 -- 初始化添加的主播信息
export const InitializeInsertAnchorApi = (params) => instance.get(`/tb.live.anchor/initialize/${params}`);

//主播管理 -- 主播直播日历 -- 查询直播历史主播的简单信息
export const GetLiveHistoryAnchorSimpleAPI = (params) => instance.get('/tb.live.history/get.anchor.simple',{params});

//主播管理 -- 主播直播日历 -- 查询直播历史记录
export const QueryAnchorLiveHistoryAPI = (params) => instance.get('/tb.live.history/get',{params});

/**
 * *************************************淘宝主播--接口****************************************************************
 */
//查询用户管理的主播数据
export const GetManageAnchorDataAPI = (params) => instance.get('/tb.live.anchor/query.page',{params});
//获取用户管理的主播简单数据
export const GetAnchorSimpleListAPI = (params) => instance.get('/tb.live.anchor/query.simple.list', {params});
//查询当前主播的简单信息
export const GetAnchorSimpleAPI = (params) => instance.get(`/tb.live.anchor/query.anchor.simple/${params}`);
//查询当前主播近期的主播数据
export const GetRealTimeLiveDataAPI = (params) => instance.get('/tb.live.history/real.time.live',{params});
//批量试用主播转正试主播
export const BatchJoinFormalAPI = (params) => instance.post('/tb.live.anchor/batch.join.formal',params);
//批量正式主播转试用主播
export const BatchJoinNormalAPI = (params) => instance.post('/tb.live.anchor/batch.join.normal',params);
//禁用主播
export const DisableAnchorAPI = (params) => instance.get('/tb.live.anchor/disable.anchor',{params});
//解除禁用主播
export const RemoveDisableAnchorAPI = (params) => instance.get('/tb.live.anchor/remove.disable.anchor',{params});
//获取满足删除条件主播数据
export const GetRemoveConditionAnchorAPI = (params) => instance.get('/tb.live.anchor/query.remove.data', {params});
//删除主播（主播失效）
export const RemoveAnchorAPI = (params) => instance.get('/tb.live.anchor/remove', {params});
//批量加入失效主播
export const BatchRemoveAnchorAPI = (params) => instance.post('/tb.live.anchor/batch.remove',params);
//------------------------------直播场次 -- 接口-------------------------------------
//获取当前主播可选择的直播场次
export const GetSelectOptionLiveAPI = (params) => instance.get('/tb.live.history/select.live.option',{params});
//获取当前直播间的商品数据
export const GetCurrentLiveItemDataAPI = (params) => instance.get('/tb.live.history/query.live.assistant',{params});
//获取直播回放地址
export const GetLiveReplyUrlAPI = (params) => instance.get('/tb.live.history/get.reply.url', {params});
//------------------------------主播销量库 -- 接口----------------------------------------------
//获取主播销量库中的商品数据
export const GetAnchorSalesItemsAPI = (params) => instance.get('/tb.anchor.sales.item/query.item',{params});
//获取主播销量库中商品是数量
export const GetAnchorSalesCountAPI = (params) => instance.get('/tb.anchor.sales.item/query.count',{params});
//获取主播销量库中是商品ids
export const GetAnchorSalesItemIdsAPI = (params) => instance.get('/tb.anchor.sales.item/query.ids',{params});
//添加商品到主播销量库中
export const AddItemAnchorSalesAPI = (params) => instance.post('/tb.anchor.sales.item/batch.add.item.sales',params);
//主播商品库删除商品
export const RemoveAnchorSalesItemAPI = (params) => instance.get('/tb.anchor.sales.item/remove.item',{params});
//获取主播销量库数据
export const QueryAnchorLiveItemDataAPI = (params) => instance.get('/tb.anchor.sales.item/query', {params});
//同步更新主播直播销量库数据
export const RenewAnchorLiveItemDataAPI = (params) => instance.get('/tb.anchor.sales.item/sycn.item.data',{params});

//---------------------------- 定向佣金商品 -- 接口 ------------------------------------------
export const QueryDirectItemDataAPI = (params) => instance.post(`/tb.direct.item/query?uid=${params.uid}&page=${params.page}&pageSize=${params.pageSize}`, params.data);

//---------------------------- 直播间商品 -- 接口 -------------------------------------------------
//缓存查询直播间商品
export const QueryLiveItemDataAPI = (params) => instance.get('/tb.live.item/query', {params});
//刷新直播间商品
export const RefreshLiveItemDataAPI = (params) => instance.get('/tb.live.item/refresh', {params});
//查询直播间商品的ID集合
export const QueryLiveItemIdListDataAPI = (params) => instance.get('/tb.live.item/query.id.list', {params});
//批量识别添加到直播间的商品
export const GetBatchLiveItemAPI = (params) => instance.post('/tb.live.item/batch.get', params);
//将商品添加到直播间中
export const InsertLiveItemAPI = (params) => instance.post('/tb.live.item/insert', params);
//删除直播间的商品
export const DeleteLiveItemAPI = (params) => instance.post('/tb.live.item/delete', params);

//---------------------------- 预告直播 -- 接口 ------------------------------------
//获取预告直播数据
export const QueryPreviewLiveDataAPI = (params) => instance.get('/tb.preview.live/query', {params});
//获取预告直播间的商品

//-----------------------------直播间 -- 接口---------------------------------------------------------
//获取主播直播间详情
export const QueryLiveRoomInfoAPI = (params) => instance.get('/tb.anchor.live.room/query.live.detail',{params});
//获取直播间的所有商品信息
export const GetLiveItemAllAPI = (params) => instance.get('/tb.anchor.live.room/query.live.item.all',{params});
//直播间获取商品信息
export const GetLiveItemAPI = (params) => instance.get('/tb.anchor.live.room/get.live.item',{params});
//直播间是否条件上过品
export const GetIsConditionAddItemAPI = (params) => instance.get('/tb.anchor.live.room/is.condition.add.item',{params});
//直播间条件添加商品
export const LiveConditionAddItemAPI = (params) => instance.get('/tb.anchor.live.room/live.condition.add.item',{params});
//直播间添加商品
export const LiveAddItemAPI = (params, data) => instance.post(`/tb.anchor.live.room/batch.live.add.item?uid=${params.uid}&liveId=${params.liveId}`,data);
//预告直播间删除商品
export const PredictLiveRemoveItemAPI = (params) => instance.get('/tb.anchor.live.room/remove.item.batch',{params});
//--------------------------------主播选品条件 -- 接口 -----------------------------------------------
//获取当前主播的设置的选品
export const GetAnchorSelectedInfoAPI = (params) => instance.get('/tb.anchor.condition/get.anchor.selected', {params});
//获取主播普通选品的条件
export const GetCommonConditionDataAPI = (params) => instance.get('/tb.anchor.condition/get.common',{params});
//获取主播高级选品的条件
export const GetOperateConditionDataAPI = (params) => instance.get('/tb.anchor.condition/get.operate',{params});
//获取主播智能选品的条件
export const GetMindConditionDataAPI = (params) => instance.get('/tb.anchor.condition/get.mind', {params});
//设置普通选品
export const SaveCommonConditionAPI = (params, data) => instance.post(`/tb.anchor.condition/save.common?uid=${params.uid}`,data);
//设置高级选品
export const SaveOperateConditionAPI = (params, data) => instance.post(`/tb.anchor.condition/save.operate?uid=${params.uid}`,data);
//设置智能选品
export const SaveMindSelectedConditionAPI = (params, data) => instance.post(`/tb.anchor.condition/save.mind?uid=${params.uid}`,data);
//获取用户批量普通选品
export const GetBatchCommonConditionDataAPI = (params) => instance.get('/tb.anchor.condition/get.batch.common',{params});
//批量设置普通选品的条件
export const BatchSaveCommonConditionAPI = (params) => instance.post('/tb.anchor.condition/batch.save.common',params);
//获取用户批量高级选品
export const GetBatchOperateConditionDataAPI = (params) => instance.get('/tb.anchor.condition/get.batch.operate',{params});
//批量设置高级选品的条件
export const BatchSaveOperateConditionAPI = (params) => instance.post('/tb.anchor.condition/batch.save.operate',params);
//获取用户批量智能选品
export const GetBatchMindConidtionDataAPI = (params) => instance.get('/tb.anchor.condition/get.batch.mind',{params});
//批量设置智能选品的条件
export const BatchSaveMindConditionAPI = (params) => instance.post('/tb.anchor.condition/batch.save.mind',params);
//-------------------------------------------- 直播讲解 -- 接口 -------------------------------------------------
//获取直播的讲解场次
export const GetLiveExplainListAPI = (params) => instance.get('/tb.live.explain/get.live.list', {params});
//获取直播讲解商品的数据
export const GetLiveExplainItemListAPI = (params) => instance.get('/tb.live.explain/get.live.item.list', {params});
//删除讲解
export const DeleteLiveExplainItemAPI = (params) => instance.get('/tb.live.explain/timemove.delete', {params});
//讲解是否已投放
export const GetLiveItemIsLaunchAPI = (params) => instance.get('/tb.live.explain/get.item.isLaunch', {params});
//投放讲解
export const LaunchLiveExplainItemAPI = (params) => instance.get('/tb.live.explain/launch.item', {params}); 
//------------------------------------------ 直播违规记录 -- 接口 --------------------------------------------------
//获取直播违规记录数据
export const getLiveViolationDataAPI = (params) => instance.get('/tb.live.health/get.punish.basic.list', {params});
//获取直播违规记录的详情数据
export const getViolationDetailAPI = (params) => instance.get('/tb.live.health/get.punish.basic.detail', {params});

/**
 * ----------------------失效主播--接口------------------------------------
 */
//获取失效主播的列表数据
export const SelectInvalidAnchorAPI = (params) => instance.get('/tb.live.anchor.invalid/select.page',{params});
//获取主播的近期直播数据
export const QueryInvalidAnchorLiveInfoAPI = (params) => instance.get('/tb.live.anchor.invalid/query.anchor.live',{params});

//主播管理 -- 测试接口
export const TestTbAPI = (params) => instance.get('/tb.live.plat.form/live.general',{params});

/**
 * ***********************************************登录淘宝 -- 接口********************************************************
 */
//账号密码登录淘宝
export const LoginAccountTbAPI = (params) => instance.post('/tb.login.account/password',params);

/**
 * ******************************************* 淘宝商品更新 -- 接口**************************************************
 */
//更新全部商品
export const RenewAllItemAPI = (params) => instance.get('/tb.renew.item/all.item', {params});
//更新排行榜商品
export const RenewTbHotItemRankingAPI = (params) => instance.get('/tb.renew.item/ranking.item',{params});
//更新热门商品
export const RenewHotItemAPI = (params) => instance.get('/tb.renew.item/hot.item', {params});
//更新高佣好物商品
export const RenewHighCommissionItemAPI = (params) => instance.get('/tb.renew.item/high.commission.item', {params});

/**
 * ************************************************淘宝商品--接口**********************************************************
 */
//---------------------------- 分页获取商品信息 -------------------------------
export const GetCommodityAPI = (params) => instance.get('/tb.commodity/get.page', {params});
//--------------------------- 查询爆品商品 ---------------------------------------
export const GetHotCommodityAPI = (params) => instance.get('/tb.commodity/get.hot.item.page', {params});
//--------------------------- 查询高佣商品 ---------------------------------------
export const GetHighCommissionCommodityAPI = (params) => instance.get('/tb.commodity/get.high.item.page', {params});

//淘宝带货商品 -- 查询机构主播可带货的商品数据
export const GetAnchorLiveCommerceItemAPI = (page,pageSize,params) => instance.post(`/tb.live.commerce.item/get.page?page=${page}&pageSize=${pageSize}`,params);
//淘宝商品 -- 商品榜单
export const SelectItemRankAPI = (params) => instance.get('/tb.commodity/get.rank.item',{params});
//----------------------------自选库---------------------------
//查询用户自选库商品数据
export const GetSelfLibraryItemAPI = (params) => instance.get('/tb.self.library/get.item.data',{params});
//查询用户自选库的商品数量
export const GetSelfLibraryItemCountAPI = (params) => instance.get('/tb.self.library/get.stat.count',{params});
//查询用户自选库中商品的id
export const QuerySelfLibraryItemIdsAPI = (params) => instance.get('/tb.self.library/get.item.id.list',{params});
//将商品添加到用户自选库中
export const SelfLibraryAddItemAPI = (params) => instance.get('/tb.self.library/save.item',{params});
//删除用户自选库商品
export const SelfLibraryRemoveItemAPI = (params) => instance.get('/tb.self.library/remove.item',{params});

/**
 * ********************************************* 主播自动预告上品 -- 接口********************************
 */
export const SwitchAutoLiveAddItemAPI = (params) => instance.get('/tb.live.anchor/auto.switch.item', {params});

/**
 * ************************************************主播周期营业额 -- 接口*****************************************
 */
export const QueryAnchorCycleTurnoverAPI = (params) => instance.get('/tb.live.overview.turnover/cycle.query',{params});

/**
 * *********************************************** 淘宝商品违规关键词 -- 接口 **************************************
 */
//获取违规关键词数据
export const GetItemViolationKeywordDataAPI = (params) => instance.get('/item.violation.keywords/get.page', {params});
//创建违规关键词数据
export const CreateItemViolationKeywordAPI = (params) => instance.post('/item.violation.keywords/create', params);
//编辑违规词
export const UpdateItemViolationKeywordAPI = (params) => instance.post('/item.violation.keywords/update', params);
//删除违规关键词数据 
export const DeleteItemViolationKeywordAPI = (params) => instance.post('/item.violation.keywords/delete', params);
//违规词扫描全部商品
export const ScanViolationItemAllAPI = () => instance.get('/item.violation.keywords/scan.all');
//根据违规词获取商品数据
export const GetViolationItemDataAPI = (params) => instance.get('/item.violation.keywords/scan', {params}); 
export const ScanItemViolationKeywordAPI = (params) => instance.get('/item.violation.keywords/scan', {params});

/**
 * *************************************************管理员--接口********************************************************
 */
//管理员--后台账号管理--关注权限的用户展示（分页）
export const GetAdministratorsListAPI = (params) => instance.get('/hei.admin.user.account/select.page',{params})
//管理员--后台账号管理--关注权限根据id删除数据
export const AdministratorsDeleteByIdApi = (params) => instance.get('/administrators/delete',{params})

//管理员--后台账号管理--关注权限根据id查询数据
export const GetAdministratorsByIdAPI = (params) => instance.get(`/hei.admin.user.account/select/${params.id}`,)

//管理员--后台账号管理--关注权限添加（获取系统登录角色）
export const GetSystemRoleAPI = (params) => instance.get('/hei.role/get',{params});

//管理员--后台账号管理--关注权限添加数据
export const AdministratorsInsertAPI = (params) => instance.post('/hei.admin.user.account/insert',params)

//管理员--后台账号管理--关注权限修改数据
export const AdministratorsUpdateByIdAPI = (params) => instance.post('/hei.admin.user.account/update',params);

//管理员--后台账号管理--关注权限修改数据
export const GetRoleMCNPowerByUserIdAPI = (params) => instance.get(`/hei.role.mcn.power/select/${params.userId}`);

//管理员--直播权限获取用户信息（展示分页，模糊查询）
export const GetLivelyListApi = (params) => instance.get('/lively/sysUserPage',{params})

//管理员--直播权限添加用户信息
export const LivelyqxInsertApi = (params) => instance.post('/lively/insert',params)

//管理员--直播权限根据id获取用户信息
export const GetLivelyByIdApi = (params) => instance.get('/lively/getLivelyOne',{params})

//管理员--直播权限根据id修改数据
export const LivelyqxUpdateByIdApi = (params) => instance.post('/lively/update',params)

//管理员--直播权限根据id删除数据
export const LivelyDeleteByIdApi = (params) => instance.get('/lively/delete',{params})

//管理员--抖音插件获取用户信息
export const DuyinqxGetTableApi = (params) => instance.get('/dyAdmin/getAdminPage',{params})

//管理员--抖音插件--根据用户百应ID获取用户问题反馈
export const DuyinGetUserFeedback = (params) => instance.get('/dyAdmin/feedback',{params})

//管理员--抖音插件--回复用户问题反馈
export const DuyinReplyUserFeedback = (params) => instance.post('/dyAdmin/reply_feedback',params)

//管理员--抖音插件创建新的用户权限
export const DuyinqxAdminInsertApi = (params) => instance.post('/dyAdmin/insert',params)

//管理员--抖音插件根据用户id获取用户信息
export const DuyinqxGetByIdApi = (params) => instance.get('/dyAdmin/getOne',{params})

//管理员--抖音插件根据用户id修改用户信息
export const DuyinqxAdminUpdateApi = (params) => instance.post('/dyAdmin/update',params)

//管理员--抖音插件根据用户id删除用户信息
export const DuyinqxAdminDeleteByIdsApi = (params) => instance.get('/dyAdmin/delete',{params})
/**
 * 管理员 -- 淘宝登录主播
 */
export const AdminSelectTbLoginAnchorAPI = (params) => instance.get('/manage.tb.anchor/select', {params});

/**
 * ************************************************管理员 -- 直播猫插件抖音本地生活*********************************************
 */
//获取插件授权登录的用户信息
export const GetDyeosUserDataAPI = (params) => instance.get('/dyeos.user/get', {params});
//创建插件用户
export const DyeosUserCreateAPI = (params) => instance.post('/dyeos.user/create', params);
//编辑插件用户
export const DyeosUserUpdateAPI = (params) => instance.post('/dyeos.user/update', params);
//删除插件用户
export const DyeosUserDeleteAPI = (params) => instance.get('/dyeos.user/delete',{params});

/**
 * 授权功能区的API
 */
//授权（抖音）--获取抖音授权登录用户的授权信息
export const DouYinAuthUserInfo = (params) => instance.get('/dy_auth/select',{params});

//授权（抖音）--时间范围查询抖音授权用户信息
export const DouYinSelectTimeRange = (params) => instance.get('/dy_auth/select/time',{params});

//授权（抖音）--获取今天授权人数
export const DouYinTodayAuthCount = (params) => instance.get('/dy_auth/today/count',{params});

//授权（抖音）--根据id获取授权用户的详情信息
export const DouYinAuthUserDetail = (params) => instance.get('/dy_auth/detail',{params});

//授权（抖音）--手动激活抖音授权用户的插件信息
export const DouYinActivationPlugin = (params) => instance.post('/dy_auth/activation',params);

//授权（抖音）--修改激活抖音授权用户的插件信息
export const DouYinActivationUpdate = (params) => instance.post('/dy_auth/activation_update',params);

//授权（抖店）--获取抖店授权登录用户的授权信息
export const DouDianAuthAdminData = (params) => instance.get('/dd_auth/select',{params});

//授权（抖店）--获取抖店授权登录用户的授权信息
export const DoudianSelectOrderInfo = (params) => instance.get('/dd_auth/selectOrder',{params});

/**
 * **************************************历史用户的API接口*********************************************
 */
//历史用户 -- 旧版本账号 -- 获取数据（分页，模糊）
export const GetHeiHistoryAdminAccountAPI = (params) => instance.get('/hei.old.account/select.page.condition',{params});

//历史用户 -- 直播互动 -- 获取数据（分页，模糊）
export const GetLivelyArtifactListAPI = (params) => instance.get('/hei.old.live.interact/select.page.condition',{params})

//历史用户--直播神器根据id删除用户信息
export const LivelyArtifactDeleteByIdAPI = (params) => instance.get('/hei.old.live.interact/delete',{params})


/**
 * **********************************************主播商品提词h5页面请求***************************************************
 */

//开播码验证主播信息
export const AnchorIdentityCheckCodeAPI = (params) => instance.post('/hei.anchor.authentication/login/',params);
//获取主播正在直播的直播间信息
export const SelectAnchorLivingAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/query.living/',{params});
//获取当前主播是否已经上过品
export const SelectAddCommodityAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/query.add/',{params});
//直播间上品
export const AddItemLivingAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/new.add.item.live/',{params});
// export const AddItemLivingAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/new.add.cate.item.live/',{params});
//获取设置条件
export const SelectMcnSetCondition = (params) => instance.get(`/hei.h5api.nottk/tb.anchor/condition/${params}`);
//获取当前商品的提词信息
export const SelectCommodityPromptAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/prompt',{params});
//直播间弹商品
export const AnchorLiveBallItemAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/live.ball.item',{params});
/********************** 直播间推送关注卡片 ******************************/
export const LivePushFollowCardAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/push.concern.card', {params});
/*********************** 直播间公告  ****************************/
//查询主播直播间公告
export const LiveNoticeGetAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/get.notice', {params});
//创建主播直播间公告
export const LiveNoticeSaveAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/save.notice', {params});
//删除主播住标间公告
export const LiveNoticeDeleteAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/delete.notice', {params});
//发送公告
export const LiveNoticeSendAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/send.notice', {params});
/********************** 智能讲解 *****************************/
//获取智能讲解设置的数据
export const GetAutoExplainAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/get.auto.explain', {params});
//设置智能讲解的开关
export const SetAutoExplainAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/set.auto.explain', {params});
/********************** 智能回复***************************** */
//获取智能回复数据
export const GetKeywordReplyAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/get.setting.reply', {params}); 
//智能回复的开关
export const SwitchKeywordReplyAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/switch.setting.reply', {params});
//保存智能回复内容
export const SaveKeywordReplyAPI = (params) => instance.post('/hei.h5api.nottk/tb.anchor/save.setting.reply', params);
/******************* 获取直播间商品******************** */
//获取直播间商品
export const GetLiveGoodsAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/query.live.goods', {params});
//商品置顶
export const LiveItemTopAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/goods.top', {params});
//取消商品置顶
export const LiveItemCancelTopAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/goods.cancel.top', {params});
//设置商品的利益点
export const SetItemBenefitAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/set.item.benefit', {params});
//批量设置商品的利益点
export const BatchSetItemBenefitAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/batch.set.item.benefit', {params});
/******************* AI直播间问答助手 ************************/
//查询Ai问答助手的数据
export const QueryLiveAIReplyAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/query.live.aiReply', {params});
//设置AI问答助手的数据
export const SetLiveAIReplyAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/set.aiReply', {params});
/******************* 直播间权益投放 ***********************/
//获取用户创建的优惠券红包信息
export const GetUserBenefitAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/get.user.benefit', {params});
//获取权益的信息
export const GetBenefitPoolAPI = (params) => instance.post('/hei.h5api.nottk/tb.anchor/get.benefit.poolId', params);
//获取优惠券红包投放记录
export const GetPutCouponLogAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/get.live.coupon.put.log', {params});
//获取权益投放的条件
export const GetBenefitConditionAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/get.benefit.condition', {params});
//投放设置条件的权益
export const PushCreateBenefitAPI = (params) => instance.post('/hei.h5api.nottk/tb.anchor/push.create.benefit', params);
//发放投放记录的权益
export const PushLogBenefitAPI = (params) => instance.get('/hei.h5api.nottk/tb.anchor/push.log.benefit', {params});



/**
 * 淘宝滑块验证
 */
export const SetSliderVerifyAPI = (params) => instance.get('/tb/slider.verify/set',{params});
