import Vue from 'vue'
//引入
import VueRouter from 'vue-router'

// import Login from '@/views/LoginTest.vue'
// import Index from '@/views/index.vue'
import HomeView from '@/views/HomeView.vue'
import store  from '@/store'

import {GetUserRoutersApi} from '@/request/api'

//安装注册
Vue.use(VueRouter)

const router = new VueRouter({
    //base:"/ui/",
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'mainlayout',
            component: () => import('@/views/layout/MainLayout.vue'),
            //重定向/home
            redirect:"/home",
            // children:[
            //     // {
            //     //     path:'/home',
            //     //     component: () => import('@/views/HomeView.vue')
            //     // },
            //     // {
            //     //     path:'/jiagou/JiagouAdd',
            //     //     component: () => import('@/views/jiagou/JiagouAdd.vue')
            //     // },
            //     // {
            //     //     path:'/jiagou/JiagouList',
            //     //     component: () => import('@/views/jiagou/JiagouList.vue')
            //     // }
            // ]
        },
        {
            path: '/h5/anchor/prompt',
            name: 'anchor',
            component: () => import('@/views/h5/login/LivePrompt.vue'),
            // meta: {
            //     titles:[]
            // }
        },
        // {
        //     path: '/taobao/test/Test/:param',
        //     name: 'test',
        //     component: () => import('@/views/taobao/test/Test.vue'),
        //     children:[
        //         {
        //             path:'/taobao/test/TestPage',
        //             component: () => import('@/views/taobao/test/TestPage.vue')
        //         },
        //     ]
        // },
        // { path: '/', component: Login },
        // { path: '/index', component: Index}
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login/LoginDemo.vue')
            // component: () => import('@/views/login/Login.vue')
        },
        {
            path:'*',
            component: () => import('@/views/404.vue')

        }
    ]
})

router.beforeEach(async (to,from,next) => {
    //console.log('to.path:',to.path)
    if(to.path === '/h5/anchor/prompt'){
        next();
        return;
    }

    //获取token
    const token = localStorage.getItem('access_token')
    //有token访问登录页面直接跳转到首页
    if(to.path === "/login" && token){
        next("/")
        return
    }

    //没有token访问首页跳转到登录页面
    if(to.path !== "/login" && !token){
        next("/login")
        return
    }

    //获取用户权限信息
    if(token && store.state.userMenuData.menuData.length == 0){
        //获取导航栏数据
        let GetUserRoutersApiRes = await GetUserRoutersApi();
        if(!GetUserRoutersApiRes){
            return;
        }
        let newUserMenuData = GetUserRoutersApiRes.data
        store.commit("userMenuData/changeMenuData",newUserMenuData)
        //上面生成菜单数据
        //以下路由数据
        let newChildrenRoutes=[{
            path:"/home",
            component: () => import('@/views/HomeView.vue'),
            meta:{titles:['首页']}
        },{
            path:"/profile",
            component: () => import('@/views/Profile.vue'),
            meta:{titles:['个人中心']}
        }]
        newUserMenuData.forEach(item=>{
            if(item.isShow == '0'){
                newChildrenRoutes.push({
                    path:item.path,
                    component: () => import (`@/views${item.path}.vue`),
                    meta:{titles:[item.title]}
                })
            }
            let ret = item.children.map(sitem=>{
                return {
                    path: sitem.path,
                    component: () => import (`@/views${sitem.path}.vue`),
                    meta:{
                        titles:[item.title,sitem.title]
                    }
                }
            })
            newChildrenRoutes = [...newChildrenRoutes,...ret]
        });
        //console.log("处理后的路由说明",newChildrenRoutes)

        //生成的数组添加到路由mainlayout子路由中
        newChildrenRoutes.forEach(item=> {
            router.addRoute("mainlayout",item)
        })
        //重点
        next(to.path)
        return

    }

    //放行
    next()
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location){
    return originalPush.call(this, location).catch(err => {})
};

//导出
export default router